import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "country-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_openBlock(), _createBlock(_component_MultiSelect, {
    modelValue: $setup.screens,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.screens) = $event)),
    options: $setup.screenList.map((o) => {
        return { name: o.displayName, value: o.id };
      }),
    optionLabel: "name",
    placeholder: "Seçiniz",
    filter: true,
    showClear: true,
    onChange: _cache[1] || (_cache[1] = ($event: any) => ($setup.onChange($event))),
    class: "multiselect-custom"
  }, {
    value: _withCtx((slotProps) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.value, (option) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "country-item country-item-value",
          key: option.id
        }, [
          _createElementVNode("div", null, _toDisplayString(option.name), 1)
        ]))
      }), 128)),
      (!slotProps.value || slotProps.value.length === 0)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(" Seçiniz ")
          ], 64))
        : _createCommentVNode("", true)
    ]),
    option: _withCtx((slotProps) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, _toDisplayString(slotProps.option.name), 1)
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "options"]))
}